/* Updated class names */
.new-consumption-box {
  border: 2px solid #e1e1e1;
  border-radius: 10px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center; 
  border: 4px double #333;
  width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 2vh;
}


.new-consumption-main-reports {
  display: flex;
  padding: 5vw;
  justify-content: space-between;
  align-items: center;
}

.new-box-title {
  font-family: "Comic Sans MS", cursive;
  font-size: 1.2rem; /* Adjust the font size as desired */
  color: #333;
  margin-bottom: 10px;
}

.new-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: #4CAF50;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}

.new-button:hover {
  background-color:  #3a893c;
}

.new-button-comic {
  font-family: "Comic Sans MS", cursive;
  /* Add any other styles for the button here */
}
