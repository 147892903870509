.box-container {
  border: 2px solid #e1e1e1;
  border-radius: 10px;
  margin: 20px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.box-title-solar {
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
  color: #333333;
}

.button {
  padding: 12px 24px;
  border: none;
  border-radius: 30px;
  background-color: #007bff;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.button:hover {
  background-color: #0056b3;
}

.button:focus {
  outline: none;
}

.button:active {
  transform: translateY(1px);
}
