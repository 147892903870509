.container_BUTTON {
    display: flex;
    justify-content: center;
    align-items: center;  
}
.button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    background-color: #4CAF50;
    color: #ffffff;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #45a049;
  }
  
  .button:active {
    background-color: #3e8e41;
  }
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
  }
  
  .loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

  .hostel_button {
    --width: 4vw;
    --height: 6vh;
    --tooltip-height: 35px;
    --tooltip-width: 90px;
    --gap-between-tooltip-to-hostel_button: 18px;
    --hostel_button-color: #76d3fc;
    --tooltip-color: #fff;
    width: var(--width);
    height: var(--height);
    background: var(--hostel_button-color);
    position: relative;
    text-align: center;
    border-radius: 0.45em;
    font-family: "Arial";
    transition: background 0.3s;
  }
  
  .hostel_button::before {
    position: absolute;
    content: attr(data-tooltip);
    width: var(--tooltip-width);
    height: var(--tooltip-height);
    background-color: var(--tooltip-color);
    font-size: 0.9rem;
    color: #111;
    border-radius: .25em;
    line-height: var(--tooltip-height);
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-hostel_button) + 10px);
    left: calc(50% - var(--tooltip-width) / 2);
  }
  
  .hostel_button::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: var(--tooltip-color);
    left: calc(50% - 10px);
    bottom: calc(100% + var(--gap-between-tooltip-to-hostel_button) - 10px);
  }
  
  .hostel_button::after,.hostel_button::before {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
  }
  
  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #111;
  }
  
  .hostel_button-wrapper,.text,.icon {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    color: #fff;
  }
  
  .text {
    top: 0;
    color: #111;
  }
  
  .text,.icon {
    transition: top 0.5s;
  }
  
  .icon {
    color: #fff;
    top: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .icon svg {
    width: 24px;
    height: 24px;
  }
  
  .hostel_button:hover {
    background: #6c18ff;
  }
  
  .hostel_button:hover .text {
    top: -100%;
  }
  
  .hostel_button:hover .icon {
    top: 0;
  }
  
  .hostel_button:hover:before,.hostel_button:hover:after {
    opacity: 1;
    visibility: visible;
  }
  
  .hostel_button:hover:after {
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-hostel_button) - 20px);
  }
  
  .hostel_button:hover:before {
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-hostel_button));
  }
  