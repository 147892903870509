/* CsvBillerComponent.css */

.csv-biller-container {
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 5px;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input[type="text"] {
    padding: 5px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }
  
  button {
    padding: 10px 15px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 15vw;
  }
  
  button:hover {
    background-color: #0069d9;
  }
  
  .data-container {
    margin-top: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 14px;
  }
  
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ccc;
  }
  
  th {
    font-weight: bold;
    background-color: #f2f2f2;
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  tr:hover {
    background-color: #f5f5f5;
  }
  
  td:first-child {
    white-space: nowrap;
  }
  
  th:first-child {
    white-space: nowrap;
  }
  
  td:not(:first-child),
  th:not(:first-child) {
    text-align: center;
  }
  