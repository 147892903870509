.sidebar {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  font-family: Comic Sans MS;
}

.sidebar ul {
  list-style-type: none;
  padding: 1vw;
  margin: 0;
}

.sidebar li {
  margin-bottom: 10vh;
}

.sidebar li a {
  display: block;
  padding: 8px;
  color: #000;
  margin-top: 62%;
  text-decoration: none;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.sidebar li a:hover {
  background-color: #ccc;
}

.content {
  /* border-radius: 3vh; */
  background-color: #f6f2f2;
  /* margin-top: 2vh; */
  /* margin-right: 2vh; */
  width: calc(100vw - 5vw); /* Adjust the percentage as needed */
  border-top-left-radius: 5vh; /* Adjust the radius as needed */
  border-bottom-left-radius: 5vh; /* Adjust the radius as needed */
  /* border: 2px solid #909090; Adjust the width and style as needed */
  box-shadow: 0 2px 2px #909090;

  /* height: 96vh; */
}


.logo {
  max-width: 10px;
  max-height: 10px;
}

.icon-container {
  position: relative; /* Add position: relative to create a positioning context for the tooltip */
  margin-top: 0.5vh;
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 1vh;
  cursor: pointer;
  
}

.icon-container::before {
  content: attr(data-tooltip); /* Set the content of the tooltip to the value of the data-tooltip attribute */
  position: absolute;
  bottom: 50%; /* Align the tooltip vertically centered with the icon */
  left: 110%; /* Position the tooltip to the right of the icon */
  background-color: rgb(46, 49, 50);
  color: #f2f0f0;
  padding: 8px;
  border-radius: 5px;
  font-size: 12px;
  opacity: 0; /* Hide the tooltip by default */
  transition: opacity 0.3s ease; /* Add a transition effect for smoother visibility */
  pointer-events: none; /* Make the tooltip unclickable */
  width: 100%;
  z-index: 100;
}

.icon-container:hover::before {
  opacity: 1; /* Show the tooltip on hover */
}

.icon-container:hover {
  background-color: #ffffff;
}

.icons-container {
  margin-top: 0.5vh;
  height: 80vh;
  background-color: transparent;
  padding: 1vw;
  border-radius: 5vw;
}

.icon-container.active {
  /* background-color: #000000; */
  background-color:#d1fec5;
  color: #000000;
  box-shadow: 0 0px 3px #909090
}

.icon-container.active .icon-name {
  color: #000000;
}

.log {
  margin-top: 10vh;
}

.icon-name {
  margin-left: 10px;
  display: flex;
  align-items: center;
  color: #000000;
  font-family: Comic Sans MS;
}

/* .icon-container:hover .icon-name {
  color: #000000;
} */

.top-bar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: transparent;
  padding: 10px;
  /* border-radius: 2px; */
  /* border-bottom: 2px solid #909090; /* Adjust the width and style as needed */
  /* box-shadow: 0 0px 3px #909090; Adjust the shadow values as needed */ 

}

/* .logo-right {
  height: 7vh;
  width: 7.2vh;
} */

.logo-left {
  height: 4vh;
  width: 10vw;
}

/* .top-title {
  font-size: 3vh;
  color: rgb(52, 52, 52);
  font-family: 'Roboto', sans-serif;
  margin-left: 24vw;
} */

.box {
  display: flex;
  align-items: center;
  background-color: #d1fec5;
  padding: 4px;
  border-radius: 1vh;
}

.weather{
  display: flex;
  flex-direction: column;
}

.weather p {
  font-family: Comic Sans MS;
  margin: 0;
}
