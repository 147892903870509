body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}


.error-message {
  color: red;
  font-size: 14px;
}

.login-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
  flex-direction: column;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("https://mdmsenquiry.com/wp-content/uploads/2016/12/n-k-p-salve-institute-of-medical-sciences-1040x555.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.login-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(8px);
}
.bottom-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f0f0f0;
  padding: 6px;
  text-align: center;
  font-weight: bold;
}
.white-box {
  width: 35%;
  height: 55%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  box-shadow: 0 2px 4px rgba(131, 125, 125, 0.2);
}

.heta-login {
  width: 32%;
  height: 8%;
  position: absolute;
  top: 86%;
  /* left: 20px; */
}

.logo-login {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: -80px;
  left: 50%;
  transform: translateX(-50%);
}



input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
}

button {
  width: 100%;
  padding: 6px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.form1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center; /* Add this line to center the form items horizontally */
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px; /* Add margin-top to separate the button from other form items */
}

button:hover {
  background-color: #45a049;
}

.sign-up-link {
  text-align: center;
  margin-top: 10px;
  color: #333;
}


.centered {
  text-align: center;
  font-family: 'Comic Sans MS';
}